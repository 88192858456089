import React from "react"
import { Button } from "react-bootstrap"
import { Carousel } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import ScrollAnimation from "react-animate-on-scroll"
import * as styles from "../styles/pages/index.module.css"

const Service = props => {
  const { title, descrip, points } = props

  return (
    <div className={styles.serviceContainer}>
      {/* <svg>
        <rect></rect>
      </svg> */}
      <h3>{title}</h3>
      <p>{descrip}</p>
      <div
        dangerouslySetInnerHTML={{
          __html: points,
        }}
      ></div>
    </div>
  )
}

export default function Home() {
  const data = useStaticQuery(graphql`
    query HomePage {
      prismicHomepage {
        data {
          comapany_desc_title {
            text
          }
          company_desc {
            text
          }
        }
      }
      prismicHomepageBodyBanner {
        items {
          bannerimage {
            fluid {
              src
            }
          }
          bannerheading {
            text
          }
        }
      }
      prismicHomepageBodyServices {
        items {
          service_description {
            text
          }
          service_heading {
            text
          }
          service_points {
            html
          }
        }
        primary {
          our_services {
            text
          }
        }
      }
    }
  `)

  const animation = {
    animateIn: "backInUp",
    animateOnce: true,
  }

  const slides = data.prismicHomepageBodyBanner.items.map(bannerData => {
    return (
      <Carousel.Item>
        <div className={styles.bannerOverlay}></div>
        <Img
          className={styles.homeBannerImg + " d-block w-100"}
          fluid={{
            ...bannerData.bannerimage.fluid,
            sizes: "",
          }}
          alt="slide"
        />
        <Carousel.Caption className={styles.bannerCaption}>
          <h2>{bannerData.bannerheading.text}</h2>
          <Link to="/get-quote">
            <Button className={styles.hireBtn}>Hire us</Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    )
  })

  return (
    <Layout>
      <SEO title="Home" />
      <Carousel controls={false} interval={10000}>
        {slides}
      </Carousel>

      <div className={styles.companyDescription}>
        <div>
          <ScrollAnimation {...animation}>
            <h3>{data.prismicHomepage.data.comapany_desc_title.text}</h3>
          </ScrollAnimation>
        </div>
        <div>
          <ScrollAnimation {...animation}>
            <p>{data.prismicHomepage.data.company_desc.text}</p>
          </ScrollAnimation>
        </div>
      </div>

      <div className={styles.serviceHeading}>
        <ScrollAnimation {...animation}>
          <h2>{data.prismicHomepageBodyServices.primary.our_services.text}</h2>
        </ScrollAnimation>
      </div>
      <div className={styles.serviceWrapper}>
        {data.prismicHomepageBodyServices.items.map((service, i) => {
          return (
            <ScrollAnimation key={i} {...animation}>
              <div className={styles.serviceWrapper}>
                <Service
                  title={service.service_heading.text}
                  descrip={service.service_description.text}
                  points={service.service_points.html}
                />
              </div>
            </ScrollAnimation>
          )
        })}
      </div>
    </Layout>
  )
}
